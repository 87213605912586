import React from 'react';
import './Setup.css';
import 'react-dropdown/style.css';
import { Navigation } from '../Navigation/Navigation.js';
import { SelectPlaylist } from './Playlist/SelectPlaylist.js';

export function Setup({ spotify, socket }) {

    return(
        <div className="body">
            <SelectPlaylist spotify={spotify}/>
            <Navigation socket={socket}/>
        </div>
    )
}